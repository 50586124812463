<template>
  <v-container fill-height>
    <ErrorViewMessage
      :type="$route.params.status || '404'"
      :message="$route.params.message || null"
    />
  </v-container>
</template>

<script>
import ErrorViewMessage from '@/components/shared/customComponents/errorView/error-view-message.vue';

export default {
  name: 'ErrorPage',
  components: {
    ErrorViewMessage,
  },
};
</script>

<style scoped>
#error-type {
  font-size: 5rem;
}
</style>
