<template>
  <v-row justify="center" data-testid="empty-list-message">
    <v-col cols="7" class="d-flex flex-column align-center text-center">
      <v-img
        max-height="175"
        max-width="175"
        :src="require('@/assets/error_view_icon.svg')"
        class="mb-8"
        eager
      ></v-img>
      <p class="error-message mb-4">ERROR {{ type }}</p>
      <p class="card-title mb-2 petrol--text">
        {{ t('Title_.header.somethingWentWrong') }}
      </p>
      <p class="section-detail mb-0 petrol--text">
        {{ errorMessage }}
      </p>
      <router-link :to="{ name: 'NewsDashboard' }">
        <p class="section-detail mt-4 link-message">
          {{ t('Msg_.backToHomepage') }}
        </p>
      </router-link>
    </v-col>
  </v-row>
</template>

<script setup>
import languages from '../../locales/languages';
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  type: {
    type: [String, Number],
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: undefined,
  },
});

// --- Computed ---
const errorMessage = computed(() => {
  return props.type.toString() === '403'
    ? t('Msg_.errorPageNotAllowed')
    : props.message || t('Msg_.pageNotFound');
});
</script>

<style scoped lang="scss">
.error-message {
  font-size: 11px;
  font-weight: 500;
  color: $petrol;
  letter-spacing: 0.8px;
}

.link-message {
  color: $blue-regular !important;
}
</style>
